import React, { useEffect } from "react"
import { createMemoryHistory } from "history"
import { Route, Router, Switch } from "react-router-dom"
import { Helmet } from "react-helmet"
import "assets/scss/material-kit-react.scss?v=1.4.0"
import "@fontsource/roboto"
import "@fontsource/roboto-slab"
// pages for this product
import LandingPage from "./LandingPage/LandingPage.jsx"

let hist = createMemoryHistory()

export default () => {
  return (
    <div>
      <Helmet htmlAttributes={{ lang: "fr" }}>
        <meta charSet="utf-8" />
        <meta
          name="keywords"
          content="laverie,automatique,libre service,moulin à vent,automatique,pressing,cabestany,perpignan,speed queen,speedqueen,blanchisserie,lavage,machine,laundromat,washndry,wash"
        />
        <meta
          name="google-site-verification"
          content="ZTwR0DvPR-AmGzJTrMV2FfZ-fsFCLpwKH267oMr7ZPM"
        />
        <title>Laverie Drop&Clean</title>
        <link rel="canonical" href="http://www.dropandclean.fr" />
      </Helmet>

      {/* <Route path="/landing-page" component={LandingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} /> */}
      <LandingPage />
    </div>
  )
}
